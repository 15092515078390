
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import MbOrderRoutingOverview from "@/components/page/mb-order-routing-overview.vue";

export default defineComponent({
  name: "double-sale-order-order-routing",
  components: { MbOrderRoutingOverview },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
